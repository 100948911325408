<script>
/**
 * Top-users component
 */

import paginator from "@/components/paginator";

export default {
  props: ["users"],
  components: {
    paginator,
  },
  data() {
    return {
      paginatedUsers:[],
      paginate:null,
      paginatelinks:null,
    };
  },
  // mounted() {
  //   this.getUsersPaginate();
  //  },
  watch:{
    users(newVal){
      this.getUsersPaginate();
    }
  },
  methods: {
    getUsersPaginate()
    {
      this.paginate = {
          first_page: this.users.first_page_url|| [],
          last_page: this.users.last_page_url || [],
          links: this.users.links || []
        };
        this.paginatelinks = this.paginate.links.map(item => ({
          url: item.url,
          label: item.label,
          active: item.active
        }));
    },
    emitUrl({url})
    {      
      this.$emit('paginationUrl', url);
    },
    getSafe(fn, defaultValue = "N/A") {
      try {
        return fn();
      } catch (error) {
        return defaultValue;
      }
    },
  },
};
</script>

<template>
  <div class="col-lg-6 col-12">
    <div class="card shadow-none border border-radius-12">
      <div class="card-body">
        <div class="float-end gray-text">
          <router-link to="/users/listing" class="gray-text fw-600">View All Users</router-link>
        </div>
        <!-- <div class="float-end">
          <b-dropdown
            variant="white"
            toggle-class="p-0"
            menu-class="dropdown-menu-end"
          >
            <template v-slot:button-content>
              <span class="text-muted">
                All Members
                <i class="mdi mdi-chevron-down ms-1"></i>
              </span>
            </template>
<b-dropdown-item href="#">Locations</b-dropdown-item>
<b-dropdown-item href="#">Revenue</b-dropdown-item>
<b-dropdown-item href="#">Join Date</b-dropdown-item>
</b-dropdown>
</div> -->
        <h4 class="card-title mb-4 ">Users</h4>

        <div data-simplebar style="max-height: 336px">
          <div class="table-responsive">
            <table class="table table-borderless table-centered table-nowrap gray-text">
              <tr>
                <th>No.</th>
                <th>User Name</th>
                <th>Contact Number</th>
                <th>Email</th>
              </tr>
              <tbody>
                <tr v-if="!users">
                  <td class="text-center" colspan="4">Record Not Found</td>
                </tr>
                <tr v-else v-for="(item, index) in users.data" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ getSafe(() => item.full_name, "N/A") }}</td>
                  <td>{{ getSafe(() => item.mobile, "N/A") }}</td>
                  <td>{{ getSafe(() => item.email, "N/A") }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="dataTables_paginate paging_simple_numbers float-end pt-3">
            <ul class="pagination pagination-rounded mb-0">
              <paginator :pagination="paginatelinks" @paginator="emitUrl"/>
            </ul>
          </div>
          <!-- enbd table-responsive-->
        </div>
        <!-- data-sidebar-->
      </div>
      <!-- end card-body-->
    </div>
    <!-- end card-->
  </div>
  <!-- end col -->
</template>
