<template>
    <div class="custom_pagination" v-if="pagination.length">
        <span v-for="(link, index) in displayedPagination" :key="index">
            <template v-if="link.isChevron">
                <span class="custom-pagination-links" @click="$emit('paginator', {url: link.url})">
                    <i :class="link.iconClass"></i>
                </span>
            </template>
            <template v-else>
                <span class="custom-pagination-links" :class="{'active-paginate': link.active}" @click="link.url && $emit('paginator', {url: link.url})">
                    {{ link.label }}
                </span>
            </template>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        pagination: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    computed: {
        displayedPagination() {
            const length = this.pagination.length;
            const activeIndex = this.pagination.findIndex(link => link.active);

            if (length > 5) {
                const pages = [
                    this.pagination[1],  
                    this.pagination[2], 
                ];

                if (activeIndex > 2 && activeIndex < length - 2) {
                    pages.push({ label: '...', url: null });
                    pages.push(this.pagination[activeIndex]);
                    pages.push({ label: '...', url: null });
                } else {
                    pages.push({ label: '...', url: null });
                }

                pages.push(this.pagination[length - 2]);  
                

                return [
                    { isChevron: true, iconClass: 'fas fa-chevron-left', url: this.pagination[0].url },
                    ...pages,
                    { isChevron: true, iconClass: 'fas fa-chevron-right', url: this.pagination[length - 1].url }
                ];
            } else {
                const pages = this.pagination.slice(1, length - 1);
                return [
                    { isChevron: true, iconClass: 'fas fa-chevron-left', url: this.pagination[0].url },
                    ...pages,
                    { isChevron: true, iconClass: 'fas fa-chevron-right', url: this.pagination[length - 1].url }
                ];
            }
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.$forceUpdate(); 
        }
    }
};
</script>